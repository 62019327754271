import type { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useSession, signIn } from 'next-auth/react'
import { useEffect } from 'react'
import { Container } from 'theme-ui'

import { BaseLayout } from '../layouts/base'
import { api } from '../data/api'

const Home: NextPage = () => {
  const router = useRouter()
  const session = useSession()

  // call /api/v1/athlete/me - 403 not logged, 200 logged

  useEffect(() => {
    if (session.status === 'unauthenticated') {
      const checkLogged = async (): Promise<string | null> => {
        if (router.query.id && router.query.nonce) {
          signIn(
            'nonce',
            {
              redirect: false,
            },
            [
              ['id', router.query.id.toString()],
              ['nonce', router.query.nonce.toString()],
            ],
          )
        }

        if (router.query.temporaryEmail && router.query.nonce) {
          return await api('/user/activate', {
            method: 'POST',
            body: JSON.stringify({
              email: router.query.temporaryEmail,
              nonce: router.query.nonce,
            }),
          })
            .then((res) => res.json())
            .then((json) => {
              signIn(
                'relogin',
                {
                  redirect: false,
                },
                [['token', json.token]],
              )

              return json.token
            })
            .then((token) => token)
        }

        return null
      }

      checkLogged()
    }
  }, [router, session.status])

  const data = session.data!;

  if (
    session.status === 'authenticated' &&
    (!data.user?.email)
  ) {
    console.log('redirect to complete registration page!')
    router.push('/complete-registration')
    return null
  }

  if (
    session.status === 'authenticated' &&
    (!data.user?.teamId)
  ) {
    console.log('redirect to profile edit!')
    router.push('/profile/edit')
    return null
  }

  if (session.status === 'authenticated') {
    router.push('/scoreboard/team/total')
  }

  return (
    <BaseLayout>
      <Head>
        <title>Zrozená k pohybu</title>
        <meta name="description" content="Aplikace Zrozená k pohybu" />
      </Head>

      <Container></Container>
    </BaseLayout>
  )
}

export default Home
