import { useRouter } from 'next/router'
import { Box } from 'theme-ui'

import { IconEnum, MenuLink } from './MenuLink'

const menuItems = [
  {
    id: 1,
    label: 'Pořadí',
    href: '/scoreboard/team/total',
    icon: IconEnum.Scoreboard,
  },
  {
    id: 2,
    label: 'Aktivity',
    href: '/activities',
    icon: IconEnum.Activities,
  },
  {
    id: 3,
    label: 'Tým',
    href: '/team',
    icon: IconEnum.Team,
  },
  {
    id: 4,
    label: 'Profil',
    href: '/profile',
    icon: IconEnum.Profile,
  },
]

export const Menu = () => {
  const router = useRouter()
  const pathname = router.pathname

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${menuItems.length}, 1fr)`,
        justifyContent: 'center',
        justifyItems: 'stretch',
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        paddingBottom: 'env(safe-area-inset-bottom)',
        backgroundColor: 'white',
        borderTop: '1px solid #D1E4FA',
        zIndex: 1000,
        '& a': {
          padding: 4,
        },
      }}
    >
      {menuItems.map(({id, label, href, icon}) => {
        return (
          <MenuLink key={id} href={href} pathname={pathname} icon={icon}>
            {label}
          </MenuLink>
        )
      })}
    </Box>
  )
}
