import Link from 'next/link'
import React from 'react'
import { Grid, Image, Link as ThemeUILink, Text } from 'theme-ui'

import homeRegular from '../images/fluent_home-24-regular.svg'
import homeFilled from '../images/fluent_home-24-filled.svg'
import scoreboardRegular from '../images/fluent_ribbon-star-24-regular.svg'
import scoreboardFilled from '../images/fluent_ribbon-star-24-filled.svg'
import profileRegular from '../images/fluent_person-24-regular.svg'
import profileFilled from '../images/fluent_person-24-filled.svg'
import activitiesRegular from '../images/fluent_text-bullet-list-square-24-regular.svg'
import activitiesFilled from '../images/fluent_text-bullet-list-square-24-filled.svg'
import teamRegular from '../images/fluent_people-24-regular.svg'
import teamFilled from '../images/fluent_people-24-filled.svg'

export enum IconEnum {
  Home = 'home',
  Scoreboard = 'scoreboard',
  Profile = 'profile',
  Activities = 'activities',
  Team = 'team',
}

interface MenuLinkProps {
  children: React.ReactNode
  pathname: string
  href: string
  icon: IconEnum
}

interface checkCurrent {
  pathname: string
  href: string
}

const icons = {
  home: {
    base: homeRegular,
    active: homeFilled,
  },
  scoreboard: {
    base: scoreboardRegular,
    active: scoreboardFilled,
  },
  profile: {
    base: profileRegular,
    active: profileFilled,
  },
  activities: {
    base: activitiesRegular,
    active: activitiesFilled,
  },
  team: {
    base: teamRegular,
    active: teamFilled,
  },
}

const checkCurrent = ({ pathname, href }: checkCurrent) => {
  const [url, level1] = pathname.split('/') // get first level of pathname (ex. scoreboard)
  const [_url, _level1] = href.split('/') // get first level of href (ex. scoreboard)

  return level1 === _level1
}

export const MenuLink = ({
  children,
  pathname,
  href,
  icon,
  ...rest
}: MenuLinkProps) => {
  const isCurrent = checkCurrent({ pathname, href })
  const iconSrc = isCurrent ? icons[icon].active.src : icons[icon].base.src

  return (
    <ThemeUILink
      sx={{ display: 'block', cursor: 'pointer' }}
      as={Link}
      href={href}
      {...rest}
    >
      <Grid
        sx={{ gap: 1, p: 2, justifyContent: 'center', justifyItems: 'center' }}
      >
        <Image src={iconSrc} alt="" aria-hidden />
        <Text sx={{ fontSize: 0 }}>{children}</Text>
      </Grid>
    </ThemeUILink>
  )
}
