import { captureException } from '@sentry/nextjs';
export const baseUrl =
  process.env.NEXT_PUBLIC_API_URL || `https://zrozenakpohybu.cz/api/v1`

export const api = (endpoint: string | Request, init: RequestInit = {}) => {
  console.log(`Calling ${baseUrl}${endpoint}`)

  return fetch(`${baseUrl}${endpoint}`, init)
    .catch(e => {
      captureException(e, {
        tags: {
          url: `${baseUrl}${endpoint}`
        },
        extra: {
          headers: init.headers ? Object.keys(init.headers) : null,
        }
      })

      throw e
    })
}

export class NotFoundException {
  constructor(
    public readonly message: string,
    public readonly status: number,
    public readonly body: any,
  ) {}
}

export const fetcher = async (url: string, token: string) => {
  if (url === null) {
    return null
  }

  const res = await fetch(`${baseUrl}${url}`, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })

  if (res.status === 200) {
    return res.json()
  }

  if (res.status === 404) {
    throw new NotFoundException('Not found', res.status, await res.json())
  }
}

export enum GroupEnum {
  Team = 'team',
  Individual = 'individual',
  // Other = 'other',
}

export enum SportEnum {
  Ride = 'ride',
  Run = 'run',
  Inline = 'inlineskate',
  Walk = 'walk',
  Swimming = 'swim',
  // Yoga = 'yoga',
  Total = 'total',
}
