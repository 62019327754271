import { useSession } from "next-auth/react";
import Head from "next/head"
import { useRouter } from "next/router";
import { Loading } from "../components/Loading";

import { Menu } from "../components/Menu";

import rajecLogo from '../images/rajec-logo.svg'

interface LayoutProps {
  showRajec?: boolean;
  children: React.ReactNode;
}

export const BaseLayout = ({ children, showRajec = true }: LayoutProps) => {
  const session = useSession()
  const router = useRouter()

  if (session.status === 'loading') {
    return <Loading />
  }

  if (session.status === 'unauthenticated') {
    router.push('/login')
    return null
  }

  return (
    <>
      <Head>
        <link rel="icon" href="/icon.png" />
      </Head>

      <Menu />

      {showRajec && <img className="absolute xs:top-8 sm:top-12 md:top-16 right-8 md:right-16 w-24 md:w-32" src={rajecLogo.src} alt="Rajec logo" />}

      <div className='pt-8 sm:pt-0'>
        {children}
      </div>
    </>
  )
}